<template>
  <template v-for="(listItem, index) in displayList" :key="index">
    <h2 v-if="index != 0" style="margin-top: 4rem">
      {{ listItem.name["it-IT"] }}
    </h2>
    <h2 v-else>{{ listItem.name["it-IT"] }}</h2>

    <div
      v-for="(faq, index) in listItem.questions"
      :key="index"
      class="accordion-component"
    >
      <div
        class="list-item-container"
        v-bind:class="{ 'list-item-active': faq.show }"
      >
        <ion-icon icon="/assets/icon/chevron-back-outline.svg"></ion-icon>
        <div class="list-item-head" @click="headerClicked(faq)">
          {{ faq.question["it-IT"] }}
        </div>
        <div class="list-item-body">
          <transition
            name="accordion"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:before-leave="beforeLeave"
            v-on:leave="leave"
          >
            <div class="body" v-show="faq.show">
              <div class="body-inner">
                <div>
                  {{ faq.answer["it-IT"] }}
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
export default {
  name: "Accordion",
  props: ["list"],
  methods: {
    beforeEnter: function (el) {
      el.style.height = "0"
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + "px"
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px"
    },
    leave: function (el) {
      el.style.height = "0"
    },
    headerClicked(item) {
      const curItem = item.show
      this.list.forEach((x) => {
        x.show = false
      })
      item.show = !curItem
    },
  },
  data() {
    return {
      displayList: this.list,
    }
  },
  watch: {
    list: function (val) {
      this.displayList = val
    },
  },
}
</script>
