<template>
  <master-layout :pageTitle="$t('pageTitle.SocialLogin')">
    <div class="social-login-btn-wrap">
      <div
        @click="facebookLogin()"
        class="social-login-btn social-login-btn-facebook"
        v-if="loggedFacebook === false"
      >
        <ion-icon :icon="logoFacebook"></ion-icon
        >{{ $t("social_login.login_with_facebook") }}
      </div>
      <div
        @click="linkedinLogin()"
        class="social-login-btn social-login-btn-linkedin"
        v-if="loggedLinkedin === false"
      >
        <ion-icon :icon="logoLinkedin"></ion-icon
        >{{ $t("social_login.login_with_linkedin") }}
      </div>
      <br />
      <div
        class="social-login-logged social-login-logged-facebook"
        v-if="loggedFacebook === true"
      >
        <ion-icon :icon="logoFacebook"></ion-icon>
        {{ $t("social_login.logged_with_facebook") }}
      </div>
      <div
        class="social-login-logged social-login-logged-linkedin"
        v-if="loggedLinkedin === true"
      >
        <ion-icon :icon="logoLinkedin"></ion-icon>
        {{ $t("social_login.logged_with_linkedin") }}
      </div>
      <ion-row class="ion-justify-content-center ion-padding-top">
        <ion-col size="auto" class="ion-padding-top ion-margin-top">
          <ion-button @click="close()">Skip social login</ion-button>
        </ion-col>
      </ion-row>
      <ion-button
        v-if="loggedFacebook && loggedLinkedin && idCampaign"
        class="mainButton"
        @click="close()"
        >{{ $t("social_login.close") }}
      </ion-button>
    </div>
  </master-layout>
</template>
<script>
import { IonCol, IonButton, IonRow, IonIcon, IonItem } from "@ionic/vue";

import Accordion from "../components/Accordion";
import ApiService from "../common/service.api";
import { translate } from "../common/helper.auth";
import UserService from "@/common/mixins/User";
import { logoFacebook, logoLinkedin } from "ionicons/icons";

export default {
  name: "SocialLogin",
  components: { IonCol, IonButton, IonRow, IonIcon },
  mixins: [UserService],
  created() {},
  data() {
    return {
      loggedFacebook: null,
      loggedLinkedin: null,
      logoFacebook,
      logoLinkedin,
      idCampaign: null,
    };
  },
  methods: {
    close() {
      this.$router.push({
        name: "campaign_social",
        params: {
          id: this.idCampaign,
        },
      });
    },
    updateStatusAutoRedirect() {
      if (this.idCampaign && this.loggedFacebook && this.loggedLinkedin) {
        this.close();
      }
    },
    updateStatus(autoredirect) {
      const that = this;
      that.getCurrentUser({ forceRefresh: true }).then((data) => {
        if (sessionStorage.getItem("socialLoginIdCampaign")) {
          that.idCampaign = sessionStorage.getItem("socialLoginIdCampaign");
        }
        that.isLoggedSocial("facebook").then((ret) => {
          that.loggedFacebook = ret;
          if (autoredirect) {
            this.updateStatusAutoRedirect();
          }
        });
        that.isLoggedSocial("linkedin").then((ret) => {
          that.loggedLinkedin = ret;
          if (autoredirect) {
            this.updateStatusAutoRedirect();
          }
        });
      });
    },
    facebookLogin() {
      this.loginFacebook().then(() => {
        this.loggedFacebook = null;
        this.updateStatus();
      });
    },
    linkedinLogin() {
      this.loginLinkedin().then(() => {
        // this.logoLinkedin = null;
        // this.updateStatus();
      });
    },
  },
  ionViewWillEnter() {
    if (this.$route.query.campaignId) {
      sessionStorage.setItem(
        "socialLoginIdCampaign",
        this.$route.query.campaignId
      );
    }
    if (this.$route.query.code) {
      this.loginLinkedin().then(() => {
        this.loggedLinkedin = null;
        this.updateStatus(true);
      });
    } else {
      this.updateStatus(true);
    }
  },
};
</script>

<style lang="scss" scoped>
.social-login-btn-wrap {
  margin: 5rem auto 0;
  max-width: 400px;
}
.social-login-btn,
.social-login-logged {
  margin: 20px auto;
  line-height: 50px;
  width: calc(100% - 50px);
  padding-left: 50px;
  text-align: center;
  position: relative;
  border-radius: 30px;
  color: #fff;
}
.social-login-btn ion-icon,
.social-login-logged ion-icon {
  position: absolute;
  left: 20px;
  top: 8px;
  width: 35px;
  height: 35px;
}
.social-login-btn-facebook,
.social-login-logged-facebook {
  background: #1877f2;
}
.social-login-btn-linkedin,
.social-login-logged-linkedin {
  background: #0a66c2;
}
.social-login-logged {
  background: #ccc;
}
.mainButton {
  width: 100%;
  margin-top: 30px;
}
</style>
